/**
=========================================================
* Material Dashboard 2 PRO React TS - v1.0.2
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState } from "react";

// formik components
import { Formik, Form } from "formik";

// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import form from "./schemas/form";
import validations from "./schemas/validations";
import AuthenticationLayout from "layouts/authentication/components/AuthenticationLayout";
import bgImage from "assets/images/bg-sign-up-basic.jpeg";
import initialValues from "./schemas/initalValues";
import MDTypography from "components/MDTypography";
import { Link, useNavigate } from "react-router-dom";
import axiosBackend from "config/backend";
import FormField from "components/FormField";

function SignUp(): JSX.Element {
  const currentValidation = validations;
  const [error, setError] = useState<string | null>(null);
  const navigate = useNavigate();

  const { username, email, password } = form;

  const submitForm = async (values: any, actions: any) => {
    try {
      const { username, email, password } = values;
      const response = await axiosBackend.post("user", {
        username,
        email,
        password,
        admin: false,
      });
      if (response.status === 200) {
        navigate("sign-up");
      } else {
        setError("Login failed. Please check your credentials.");
      }
    } catch (error) {
      setError("An error occurred during login. Please try again later.");
    }
  };

  return (
    <AuthenticationLayout image={bgImage}>
      <Formik
        initialValues={initialValues}
        validationSchema={currentValidation}
        onSubmit={submitForm}
      >
        <Form autoComplete="off">
          <Card>
            <MDBox
              variant="gradient"
              bgColor="info"
              borderRadius="lg"
              coloredShadow="success"
              mx={2}
              mt={-3}
              p={3}
              mb={1}
              textAlign="center"
            >
              <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
                Join us today
              </MDTypography>
              <MDTypography display="block" variant="button" color="white" my={1}>
                Enter your email and password to register
              </MDTypography>
            </MDBox>
            <MDBox pt={4} pb={3} px={3}>
              <MDBox mb={2}>
                <FormField type={username.type} label={username.label} name={username.name} />
              </MDBox>
              <MDBox mb={2}>
                <FormField type={email.type} label={email.label} name={email.name} />
              </MDBox>
              <MDBox mb={2}>
                <FormField type={password.type} label={password.label} name={password.name} />
              </MDBox>
              <MDBox mt={4} mb={1}>
                <MDButton type="submit" variant="gradient" color="info" fullWidth>
                  Sign Up
                </MDButton>
              </MDBox>
              <MDBox mt={3} mb={1} textAlign="center">
                <MDTypography variant="button" color="text">
                  Already have an account?{" "}
                  <MDTypography
                    component={Link}
                    to="/sign-in"
                    variant="button"
                    color="info"
                    fontWeight="medium"
                    textGradient
                  >
                    Sign In
                  </MDTypography>
                </MDTypography>
              </MDBox>
            </MDBox>
          </Card>
        </Form>
      </Formik>
    </AuthenticationLayout>
  );
}

export default SignUp;
