/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @material-ui core components
import { useEffect, useState } from "react";
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDSnackbar from "components/MDSnackbar";
import axiosBackend from "config/backend";
import { copyToClipboard, generateYAMLText } from "./utils";

// Define the types for the label and value props
interface InformationFieldProps {
  label: string;
  value: string | JSX.Element;
}

function InformationField({ label, value }: InformationFieldProps) {
  return (
    <>
      <MDBox
        display="flex"
        justifyContent="space-between"
        alignItems={{ xs: "flex-start", sm: "center" }}
        flexDirection={{ xs: "column", sm: "row" }}
      >
        <MDTypography variant="body2" color="text">
          {label}
        </MDTypography>
        <MDTypography variant="button" color="text" fontWeight="regular">
          {value}
        </MDTypography>
      </MDBox>
      <Divider />
    </>
  );
}

function Information(): JSX.Element {
  const [data, setData] = useState<any>(null);
  const [error, setError] = useState<Error | null>(null);
  const [masterData, setMasterData] = useState<{ [key: string]: string }>({});
  const [masterDataLoaded, setMasterDataLoaded] = useState(false);

  const handleConfigure = async (endpointId: string) => {
    try {
      const response = await axiosBackend.get(`/endpoint/${endpointId}`);
      if (response.status === 200) {
        return response.data;
      } else {
        console.error("Error configuring the machine.");
        throw new Error("Error configuring the machine.");
      }
    } catch (error) {
      console.error("Error configuring the machine:", error);
      throw error;
    }
  };

  useEffect(() => {
    const pathnameParts = window.location.pathname.split("/");
    const endpointId = pathnameParts[pathnameParts.length - 1];

    handleConfigure(endpointId)
      .then((responseData) => {
        setData(responseData);
      })
      .catch((error) => {
        setError(error);
      });
  }, []);

  useEffect(() => {
    // Fetch the list of master IDs
    axiosBackend
      .get("/id-masters")
      .then((response) => {
        if (response.status === 200) {
          // Extract the master IDs from the response data
          const masterIds = response.data;

          // Initialize an object to store the master data (ID to hostname mapping)
          const masterDataObject = {} as { [key: string]: string };

          // Fetch hostname for each master ID
          Promise.all(
            masterIds.map(async (masterId: string | number) => {
              const hostnameResponse = await axiosBackend.get(`/hostname/${masterId}`);
              if (hostnameResponse.status === 200) {
                // Add the master data to the object
                masterDataObject[masterId] = hostnameResponse.data.hostname;
              }
            })
          )
            .then(() => {
              // Update the state with the master data
              setMasterData(masterDataObject);
              // Set masterDataLoaded to true when the data is loaded
              setMasterDataLoaded(true);
            })
            .catch((error) => {
              console.error("Error fetching master data:", error);
            });
        } else {
          console.error("Error fetching master IDs.");
        }
      })
      .catch((error) => {
        console.error("Error fetching master IDs:", error);
      });
  }, []);

  if (error) {
    return (
      <div>
        <p>Error: {error.message}</p>
      </div>
    );
  }

  if (!data) {
    return <div></div>;
  }

  return (
    <Card id="information" sx={{ overflow: "visible" }}>
      <MDBox
        pr={3}
        display="flex"
        justifyContent="space-between"
        alignItems={{ xs: "flex-start", sm: "center" }}
        flexDirection={{ xs: "column", sm: "row" }}
      >
        <MDBox p={3} lineHeight={1}>
          <MDBox mb={1}>
            <MDTypography variant="h5">Information</MDTypography>
          </MDBox>
        </MDBox>
        <MDBox display="flex" flexDirection={{ xs: "column", sm: "row" }}>
          <MDBox ml={{ xs: 0, sm: 1 }} mt={{ xs: 1, sm: 0 }}>
            <MDButton
              variant="gradient"
              color="dark"
              sx={{ height: "100%" }}
              onClick={() => {
                const yamlText = generateYAMLText(data);
                copyToClipboard(yamlText);
              }}
            >
              copy configuration
            </MDButton>
          </MDBox>
        </MDBox>
      </MDBox>
      <MDBox p={3}>
        <InformationField label="Owner Username" value={data.owner_username} />
        <InformationField label="Is Master" value={data.is_master.toString()} />
        <InformationField label="Endpoint Name" value={data.endpoint_name} />
        <InformationField label="Created At" value={data.created_at} />
        <InformationField label="Public IP" value={data.public_ip} />
        <InformationField label="Private IP" value={data.private_ip} />
        <InformationField label="Mesh IP" value={data.mesh_ip} />
        <InformationField label="Last Status Message" value={data.last_status_message} />
      </MDBox>
    </Card>
  );
}

export default Information;
