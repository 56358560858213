import axios from "axios";

const isLocalConnection: boolean =
  window.location.hostname === "localhost" ||
  window.location.hostname.startsWith("192.168.") ||
  window.location.hostname.startsWith("10.") ||
  window.location.hostname.startsWith("172.");

const protocol: string = window.location.protocol;
const APISuffix = "/api/v1";
const backendURL: string = isLocalConnection
  ? `http://${window.location.hostname}:8000${APISuffix}`
  : `${protocol}//${window.location.hostname}${APISuffix}`;

const axiosBackend = axios.create({
  baseURL: `${backendURL}`,
});

// Add an interceptor to handle 401 responses
axiosBackend.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status === 401) {
      localStorage.removeItem("access_token");
      // Redirect to the home page or any other URL of your choice
      window.location.href = "/";
    }
    return Promise.reject(error);
  }
);

export default axiosBackend;
