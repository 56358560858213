import { useEffect } from "react";

function Logout(): JSX.Element {
  useEffect(() => {
    // Remove the access_token from localStorage
    localStorage.removeItem("access_token");
    window.location.reload();
  }, []);

  return <div></div>;
}

export default Logout;
