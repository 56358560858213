const form = {
  new_password: {
    name: "new_password",
    label: "New password",
    type: "password",
    errorMsg: "New password is required.",
    invalidMsg: "The new password should be more than 6 characters.",
  },
};

export default form;
