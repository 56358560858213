const form = {
  email: {
    name: "email",
    label: "Email Address",
    type: "email",
    errorMsg: "Email address is required.",
    invalidMsg: "Invalid email address.",
  },
};

export default form;
