import * as Yup from "yup";
import form from "layouts/authentication/sign-in/schemas/form";

const validations = Yup.object().shape({
  [form.username.name]: Yup.string().required(form.username.errorMsg),
  [form.password.name]: Yup.string()
    .required(form.password.errorMsg)
    .min(6, form.password.invalidMsg),
});

export default validations;
