/**
=========================================================
* Material Dashboard 2 PRO React TS - v1.0.2
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useMemo, useState } from "react";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DashboardLayout from "composite-components/LayoutContainers/DashboardLayout";
import DashboardNavbar from "composite-components/Navbars/DashboardNavbar";
import Footer from "composite-components/Footer";
import DataTable from "composite-components/Tables/DataTable";
import axiosBackend from "config/backend";
import TagDataProvider from "../endpoints/tagDataProvider";
import { Tooltip, IconButton } from "@mui/material";
import PowerSettingsNewIcon from "@mui/icons-material/PowerSettingsNew";
import SettingsIcon from "@mui/icons-material/Settings";
import { CellProps } from "react-table";
import { Link } from "react-router-dom";

function Endpoints(): JSX.Element {
  const [rows, setRows] = useState([]);
  const access_token = localStorage.getItem("access_token");

  // Fetch data function (without WebSockets)
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axiosBackend.get("/endpoints", {
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        });

        const fetchedData = await Promise.all(
          response.data.map(async (item: any) => {
            const subdomain = `${item.endpoint_name}.${item.owner_username}.${
              item.is_master ? "master" : "worker"
            }`;

            try {
              const statusResponse = await axiosBackend.get(`/status/${item.endpoint_id}`, {
                headers: {
                  Authorization: `Bearer ${access_token}`,
                },
              });

              const updatedItem = {
                ...item,
                status: statusResponse.data.status,
                mesh_network: statusResponse.data.mesh_network,
                subdomain: subdomain,
                ...(statusResponse.data.status === 3 && {
                  connected_to: statusResponse.data.connected_to,
                }),
              };

              return updatedItem;
            } catch (error) {
              console.error(`Error fetching status for endpoint ${item.endpoint_id}:`, error);
              return { ...item, status: 0 }; // Assign default status on error
            }
          })
        );

        setRows(fetchedData);
      } catch (error) {
        console.error("Error fetching data: ", error);
      }
    };

    fetchData(); // Fetch data once when the component mounts
  }, [access_token]);

  const columns = useMemo(
    () => [
      {
        Header: "Power",
        disableSortBy: true,
        accessor: "",
        Cell: ({ row }: CellProps<any>) => {
          const handleRestart = async (endpointId: any) => {
            try {
              const response = await axiosBackend.get(`/restart/${endpointId}`);
              if (response.status !== 200) {
                console.error("Error restarting the machine.");
              }
            } catch (error) {
              console.error("Error restarting the machine:", error);
            }
          };

          return (
            <MDBox display="flex" alignItems="center">
              {row.original.status === 3 && (
                <Tooltip title="Restart endpoint">
                  <IconButton
                    onClick={() => handleRestart(row.original.endpoint_id)}
                    style={{ backgroundColor: "#3498db", color: "#ffffff" }}
                  >
                    <PowerSettingsNewIcon />
                  </IconButton>
                </Tooltip>
              )}
            </MDBox>
          );
        },
      },
      { Header: "Endpoint", accessor: "subdomain" },
      { Header: "Last Status Message", accessor: "last_status_message" },
      { Header: "Public IP", accessor: "public_ip" },
      { Header: "Mesh IP", accessor: "mesh_ip" },
      {
        Header: "Status",
        accessor: "status",
        Cell: ({ value }: { value: number }) => {
          const statusTag = TagDataProvider.getTags().data.find((tag) => tag.id === value);

          return (
            <MDBox
              sx={{
                backgroundColor: statusTag?.color || "grey",
                borderRadius: 16,
              }}
              px={2}
            >
              <MDTypography variant="caption" fontWeight="medium" color="white">
                {statusTag?.name || "Loading"}
              </MDTypography>
            </MDBox>
          );
        },
      },
      {
        Header: "Mesh Network",
        accessor: "mesh_network",
        Cell: ({ value }: { value: boolean }) => {
          const statusTagId = value ? 4 : 5;
          const statusTag = TagDataProvider.getTags().data.find((tag) => tag.id === statusTagId);

          return (
            <MDBox
              sx={{
                backgroundColor: statusTag?.color || "grey",
                borderRadius: 16,
              }}
              px={2}
            >
              <MDTypography variant="caption" fontWeight="medium" color="white">
                {statusTag?.name || "Unknown"}
              </MDTypography>
            </MDBox>
          );
        },
      },
      {
        Header: "Configure",
        disableSortBy: true,
        accessor: "",
        Cell: ({ row }: CellProps<any>) => (
          <MDBox display="flex" alignItems="center">
            <Link to={`/endpoint/${row.original.endpoint_id}`}>
              <Tooltip title="Configure endpoint">
                <IconButton style={{ color: "#333333" }}>
                  <SettingsIcon />
                </IconButton>
              </Tooltip>
            </Link>
          </MDBox>
        ),
      },
    ],
    []
  );

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Card>
          <MDBox p={3} lineHeight={1}>
            <MDTypography variant="h5" fontWeight="medium">
              Endpoints
            </MDTypography>
            <MDTypography variant="button" color="text">
              Your endpoints
            </MDTypography>
          </MDBox>
          <DataTable table={{ columns, rows }} canSearch />
        </Card>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Endpoints;
