const form = {
  username: {
    name: "username",
    label: "Username",
    type: "text",
    errorMsg: "Username is required.",
  },
  password: {
    name: "password",
    label: "Password",
    type: "password",
    errorMsg: "Password is required.",
    invalidMsg: "Password should be more than 6 characters.",
  },
};

export default form;
