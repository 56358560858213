const form = {
  username: {
    name: "username",
    label: "Username",
    type: "text",
    errorMsg: "Username is required.",
  },
  email: {
    name: "email",
    label: "Email Address",
    type: "email",
    errorMsg: "Email address is required.",
    invalidMsg: "Invalid email address.",
  },
  password: {
    name: "password",
    label: "Password",
    type: "password",
    errorMsg: "Password is required.",
    invalidMsg: "Password should be more than 6 characters.",
  },
};

export default form;
