/**
=========================================================
* Material Dashboard 2 PRO React TS - v1.0.2
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/** 
  All of the routes for the Material Dashboard 2 PRO React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Sidenav.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav. 
  3. The `type` key with the `divider` value is used for a divider between Sidenav items.
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that contains other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  8. The `route` key is used to store the route location which is used for the react router.
  9. The `href` key is used to store the external links location.
  10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  10. The `component` key is used to store the component of its route.
*/

// Material Dashboard 2 PRO React layouts

// Material Dashboard 2 PRO React TS components
import MDAvatar from "components/MDAvatar";

// @mui icons
import Icon from "@mui/material/Icon";

// Images
import Endpoints from "layouts/dashboard/endpoints";
import SignIn from "layouts/authentication/sign-in";
import SignUp from "layouts/authentication/sign-up";
import ForgotPassword from "layouts/authentication/forgot-password";
import Logout from "layouts/authentication/logout";
import PasswordReset from "layouts/authentication/password-reset";
import EndpointSettings from "layouts/settings";

const routes = [
  {
    type: "collapse",
    name: "User",
    key: "user",
    loggedIn: true,
    admin: false,
    visible: true,
    icon: (
      <MDAvatar src="https://cdn-icons-png.flaticon.com/512/219/219969.png" alt="User" size="sm" />
    ),
    collapse: [
      {
        name: "Logout",
        key: "logout",
        route: "/logout",
        visible: true,
        component: <Logout />,
      },
    ],
  },
  { type: "divider", key: "divider-1" },
  {
    type: "collapse",
    name: "Dashboard",
    key: "dashboard",
    loggedIn: true,
    admin: false,
    visible: true,
    icon: <Icon fontSize="medium">dashboard</Icon>,
    collapse: [
      {
        name: "Endpoints",
        key: "endpoints",
        route: "/dashboard",
        visible: true,
        component: <Endpoints />,
      },
      {
        name: "Endpoint",
        key: "endpoint",
        route: "/endpoint/*",
        visible: false,
        component: <EndpointSettings />,
      },
    ],
  },
  {
    type: "collapse",
    name: "Authentication",
    key: "authentication",
    loggedIn: false,
    admin: false,
    visible: false,
    icon: <Icon fontSize="medium">content_paste</Icon>,
    collapse: [
      {
        name: "Sign In",
        key: "sign-in",
        route: "/sign-in",
        visible: false,
        component: <SignIn />,
      },
      {
        name: "Sign Up",
        key: "sign-up",
        route: "/sign-up",
        visible: false,
        component: <SignUp />,
      },
      {
        name: "Forgot Password",
        key: "forgot-password",
        route: "/forgot-password",
        visible: false,
        component: <ForgotPassword />,
      },
      {
        name: "Password Reset",
        key: "password-reset",
        route: "/password-reset/*",
        visible: false,
        component: <PasswordReset />,
      },
    ],
  },
];

export default routes;
