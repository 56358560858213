/**
=========================================================
* Material Dashboard 2 PRO React TS - v1.0.2
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

// Authentication layout components
import AuthenticationLayout from "layouts/authentication/components/AuthenticationLayout";

// Images
import bgImage from "assets/images/bg-reset-basic.jpeg";
import initialValues from "./schemas/initalValues";
import validations from "./schemas/validations";
import { Form, Formik } from "formik";
import FormField from "components/FormField";
import form from "./schemas/form";
import axiosBackend from "config/backend";
import MDSnackbar from "components/MDSnackbar";
import { useState } from "react";
import { Link } from "react-router-dom";

function ForgotPassword(): JSX.Element {
  const currentValidation = validations;
  const { email } = form;
  const [successSB, setSuccessSB] = useState<boolean>(false);
  const [errorSB, setErrorSB] = useState<boolean>(false);
  const openSuccessSB = () => setSuccessSB(true);
  const closeSuccessSB = () => setSuccessSB(false);
  const openErrorSB = () => setErrorSB(true);
  const closeErrorSB = () => setErrorSB(false);

  const submitForm = async (values: any, actions: any) => {
    try {
      const { email } = values;
      const response = await axiosBackend.post("forgot-password", {
        email,
      });
      if (response.status === 200) {
        openSuccessSB();
      } else {
        openErrorSB();
      }
    } catch (error) {
      openErrorSB();
    }
  };

  const renderSuccessSB = (
    <MDSnackbar
      color="success"
      icon="check"
      title="Password Recovery Successful"
      content="A password recovery link has been sent to your email address."
      dateTime="Now"
      open={successSB}
      onClose={closeSuccessSB}
      close={closeSuccessSB}
      bgWhite
    />
  );

  const renderErrorSB = (
    <MDSnackbar
      color="error"
      icon="warning"
      title="Password Recovery Failed"
      content="Failed to send the password recovery email. Please try again."
      dateTime="Now"
      open={errorSB}
      onClose={closeErrorSB}
      close={closeErrorSB}
      bgWhite
    />
  );

  return (
    <AuthenticationLayout image={bgImage}>
      <Formik
        initialValues={initialValues}
        validationSchema={currentValidation}
        onSubmit={submitForm}
      >
        <Form autoComplete="off">
          <Card>
            <MDBox
              variant="gradient"
              bgColor="info"
              borderRadius="lg"
              coloredShadow="success"
              mx={2}
              mt={-3}
              py={2}
              mb={1}
              textAlign="center"
            >
              <MDTypography variant="h3" fontWeight="medium" color="white" mt={1}>
                Recover Password
              </MDTypography>
              <MDTypography display="block" variant="button" color="white" my={1}>
                You will receive an e-mail in maximum 60 seconds
              </MDTypography>
            </MDBox>
            <MDBox pt={4} pb={3} px={3}>
              <MDBox>
                <MDBox mb={2}>
                  <FormField type={email.type} label={email.label} name={email.name} />
                </MDBox>
                <MDBox mt={3} mb={1}>
                  <MDButton type="submit" variant="gradient" color="info" fullWidth>
                    Recover
                  </MDButton>
                </MDBox>
                {renderSuccessSB}
                {renderErrorSB}
              </MDBox>
              <MDBox textAlign="center">
                <MDTypography
                  component={Link}
                  to="/sign-in"
                  variant="button"
                  color="info"
                  fontWeight="medium"
                  textGradient
                >
                  Back to Sign In
                </MDTypography>
              </MDBox>
            </MDBox>
          </Card>
        </Form>
      </Formik>
    </AuthenticationLayout>
  );
}

export default ForgotPassword;
