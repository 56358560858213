export const generateYAMLText = (row: { endpoint_name: any; is_master: any; token: any }) => {
  let image = row.is_master ? "benilies/nopasaran-master" : "benilies/nopasaran-worker";

  let yamlText = `version: '3'
services:
  ${row.endpoint_name}:
    container_name: ${row.endpoint_name}
    image: ${image}
    environment:
      - ENDPOINT_NAME=${row.endpoint_name}
      - AUTHORIZATION_TOKEN=${row.token}
      - ROLE=${row.is_master ? "master" : "worker"}
    restart: always
    network_mode: "host"
    cap_add:
      - NET_ADMIN
      - SYS_PTRACE
  watchtower:
    image: containrrr/watchtower
    volumes:
      - /var/run/docker.sock:/var/run/docker.sock
    restart: always
    command: ${row.endpoint_name} --interval 60 --cleanup`;
  return yamlText;
};

export const copyToClipboard = async (text: string) => {
  try {
    // Try using the modern Clipboard API
    if (navigator.clipboard) {
      await navigator.clipboard.writeText(text);
      console.log("Text copied to clipboard.");
    } else {
      // Fallback for browsers that do not support navigator.clipboard
      const textArea = document.createElement("textarea");
      textArea.value = text;
      document.body.appendChild(textArea);
      textArea.select();
      document.execCommand("copy");
      document.body.removeChild(textArea);
      console.log("Text copied to clipboard (fallback).");
    }
  } catch (error) {
    console.error("Error copying to clipboard: ", error);
  }
};
