import * as Yup from "yup";
import form from "layouts/authentication/password-reset/schemas/form";

const validations = Yup.object().shape({
  [form.new_password.name]: Yup.string()
    .required(form.new_password.errorMsg)
    .min(6, form.new_password.invalidMsg),
});

export default validations;
