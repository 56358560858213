interface Tag {
  id: number;
  name: string;
  color: string;
}

const tagsData: Tag[] = [
  {
    id: 0,
    name: "LOADING",
    color: "#808080", // Gray color for loading
  },
  {
    id: 1,
    name: "DOWN",
    color: "#FF5733", // Red-Orange color for down status
  },
  {
    id: 2,
    name: "CONNECTING",
    color: "#F1C40F", // Golden Yellow color for connecting status
  },
  {
    id: 3,
    name: "UP",
    color: "#4CAF50", // Green color for up status
  },
  {
    id: 4,
    name: "CONNECTED",
    color: "#3498DB", // Blue color for connected status
  },
  {
    id: 5,
    name: "DISCONNECTED",
    color: "#000000", // Black color for disconnected status
  },
];

const TagDataProvider = {
  getTags: () => {
    return {
      data: tagsData,
    };
  },
};

export default TagDataProvider;
