/**
=========================================================
* Material Dashboard 2 PRO React TS - v1.0.2
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState } from "react";

// react-router-dom components
import { Link } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

// Authentication layout components
import AuthenticationLayout from "layouts/authentication/components/AuthenticationLayout";

// Images
import bgImage from "assets/images/bg-sign-in-basic.jpeg";
import axiosBackend from "config/backend";
import MDSnackbar from "components/MDSnackbar";
import { Form, Formik } from "formik";
import FormField from "components/FormField";
import form from "./schemas/form";
import initialValues from "./schemas/initalValues";
import validations from "./schemas/validations";

function SignIn(): JSX.Element {
  const currentValidation = validations;
  const { username, password } = form;
  const [errorSB, setErrorSB] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const openErrorSB = () => setErrorSB(true);
  const closeErrorSB = () => setErrorSB(false);

  const submitForm = async (values: any, actions: any) => {
    try {
      const { username, password } = values;
      const response = await axiosBackend.post("sign-in", {
        username,
        password,
      });
      if (response.status === 200) {
        const { access_token } = response.data;
        localStorage.setItem("access_token", access_token);
        window.location.reload();
      } else {
        setError("Login failed. Please check your credentials.");
        openErrorSB();
      }
    } catch (error) {
      openErrorSB();
      setError("An error occurred during login. Please try again later.");
    }
  };

  const renderErrorSB = (
    <MDSnackbar
      color="error"
      icon="warning"
      title="Login Failed"
      content="Please check your credentials and try again."
      dateTime="Now"
      open={errorSB}
      onClose={closeErrorSB}
      close={closeErrorSB}
      bgWhite
    />
  );

  return (
    <AuthenticationLayout image={bgImage}>
      <Formik
        initialValues={initialValues}
        validationSchema={currentValidation}
        onSubmit={submitForm}
      >
        <Form autoComplete="off">
          <Card>
            <MDBox
              variant="gradient"
              bgColor="info"
              borderRadius="lg"
              coloredShadow="info"
              mx={2}
              mt={-3}
              p={2}
              mb={1}
              textAlign="center"
            >
              <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
                Sign in
              </MDTypography>
              <MDTypography display="block" variant="button" color="white" my={1}>
                Enter your email and password to Sign In
              </MDTypography>
            </MDBox>
            <MDBox pt={4} pb={3} px={3}>
              <MDBox>
                <MDBox mb={2}>
                  <FormField type={username.type} label={username.label} name={username.name} />
                </MDBox>
                <MDBox mb={2}>
                  <FormField type={password.type} label={password.label} name={password.name} />
                </MDBox>
                <MDBox mt={4} mb={1}>
                  <MDButton type="submit" variant="gradient" color="info" fullWidth>
                    Sign In
                  </MDButton>
                </MDBox>
                <MDBox textAlign="center">
                  <MDTypography
                    component={Link}
                    to="/forgot-password"
                    variant="button"
                    color="info"
                    fontWeight="medium"
                    textGradient
                  >
                    Forgot your password?
                  </MDTypography>
                </MDBox>
                <MDBox mt={2} mb={1} textAlign="center">
                  <MDTypography variant="button" color="text">
                    Don&apos;t have an account?{" "}
                    <MDTypography
                      component={Link}
                      to="/sign-up"
                      variant="button"
                      color="info"
                      fontWeight="medium"
                      textGradient
                    >
                      Sign up
                    </MDTypography>
                    {renderErrorSB}
                  </MDTypography>
                </MDBox>
              </MDBox>
            </MDBox>
          </Card>
        </Form>
      </Formik>
    </AuthenticationLayout>
  );
}

export default SignIn;
